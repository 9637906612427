<template>
  <div class="card-wrapper">
    <div class="card">

      <div class="img-wrapper">
        <div class="responsive-img">
          <img src="@/img/ballIcon/qxc_icon.png" class="icon" />
        </div>
      </div>
      <a href="https://www.lottery.gov.cn/kj/kjlb.html?qxc" target="_blank" rel="noopener">
        <img src="@/img/H5/link.png" alt="">
      </a>
    </div>
    <div class="card">
      <div class="img-wrapper">
        <div class="responsive-img">
          <img src="@/img/ballIcon/hklhc_icon.png" class="icon" />
        </div>
      </div>
      <a href="https://bet.hkjc.com/marksix/Results.aspx?lang=ch" target="_blank" rel="noopener">
        <img src="@/img/H5/link.png" alt="">
      </a>
    </div>
    <div class="card">
      <div class="img-wrapper">
        <div class="responsive-img">
          <img src="@/img/ballIcon/kl8lhc_icon.png" class="icon" />
        </div>
      </div>
      <a href="https://www.cwl.gov.cn/ygkj/kjzb/" target="_blank" rel="noopener">
        <img src="@/img/H5/link.png" alt="">
      </a>
    </div>
    <div class="card">
      <div class="img-wrapper">
        <div class="responsive-img">
          <img src="@/img/ballIcon/Pl3_icon.png" class="icon" />
        </div>
      </div>
      <a href="https://www.lottery.gov.cn/plwf/index.html" target="_blank" rel="noopener">
        <img src="@/img/H5/link.png" alt="">
      </a>
    </div>
    <div class="card">
      <div class="img-wrapper">
        <div class="responsive-img">
          <img src="@/img/ballIcon/Pl5_icon.png" class="icon" />
        </div>
      </div>
      <a href="https://www.lottery.gov.cn/plwf/index.html" target="_blank" rel="noopener">
        <img src="@/img/H5/link.png" alt="">
      </a>
    </div>
     <div class="card">
      <div class="img-wrapper">
        <div class="responsive-img">
          <img src="@/img/ballIcon/penta_icon.png" class="icon" />
        </div>
      </div>
      <a href="https://www.uk-wl.co.uk/lottoresult/" target="_blank" rel="noopener">
        <img src="@/img/H5/link.png" alt="">
      </a>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Sites',
}
</script>

<style scoped lang="scss">
.card-wrapper{
  padding-top:70px;
  display:grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 18px;
  margin:0 15px;
  padding-bottom:80px;
}
.card{
  box-shadow: 0 0 8px rgba(0,0,0,.25);
  border-radius:5px;
  background: #fff;
  display:flex;
  flex-direction: column;
  align-items: center;
  display: -webkit-flex;
  -webkit-align-items: center;
  justify-content: center;
  padding:10px;
  @media(min-width:768px){
    margin:15px;
  }
}
.img-wrapper{
  margin-bottom:15px;
  width:80px;
  @media(min-width:768px){
    width:150px;
  }
}
.responsive-img{
  position:relative;
  height:0;
  padding-bottom:100%;
}
img.icon{
 position:absolute;
  width:100%;
  height:100%;
}

</style>

